exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-case-studies-page-js": () => import("./../../../src/templates/case-studies-page.js" /* webpackChunkName: "component---src-templates-case-studies-page-js" */),
  "component---src-templates-case-study-page-js": () => import("./../../../src/templates/case-study-page.js" /* webpackChunkName: "component---src-templates-case-study-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-default-page-js": () => import("./../../../src/templates/default-page.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-news-archive-js": () => import("./../../../src/templates/news-archive.js" /* webpackChunkName: "component---src-templates-news-archive-js" */),
  "component---src-templates-news-single-js": () => import("./../../../src/templates/news-single.js" /* webpackChunkName: "component---src-templates-news-single-js" */),
  "component---src-templates-system-page-js": () => import("./../../../src/templates/system-page.js" /* webpackChunkName: "component---src-templates-system-page-js" */),
  "component---src-templates-systems-page-js": () => import("./../../../src/templates/systems-page.js" /* webpackChunkName: "component---src-templates-systems-page-js" */)
}

