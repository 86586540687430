import React, { createContext, useState } from 'react';

const defaultValues = {
  isOverlayActive: false,
  toggleOverlayActive: () => {},
  isPopupFormOpen: false,
  togglePopupFormOpen: () => {},
  popupFormType: 'callback',
};

export const SiteContext = createContext(defaultValues);

export const SiteProvider = ({ children }) => {
  const [isOverlayActive, setOverlayActive] = useState(false);
  const toggleOverlayActive = () => setOverlayActive(!isOverlayActive);
  const [isPopupFormOpen, setPopupFormOpen] = useState(false);
  const togglePopupFormOpen = () => {
    setOverlayActive(!isPopupFormOpen);
    setPopupFormOpen(!isPopupFormOpen);
  };
  const [popupFormType, setPopupFormType] = useState('callback');

  return (
    <SiteContext.Provider
      value={{
        ...defaultValues,
        isOverlayActive,
        toggleOverlayActive,
        isPopupFormOpen,
        togglePopupFormOpen,
        popupFormType,
        setPopupFormType,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};
